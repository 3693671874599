@import "../../colors.scss";

.nextButton {
	margin: 0;
	padding: 0;
	height: 70px;
	border: none;
	background-color: transparent;

	svg {
		fill: $color-7472-c;
	}

	> div {
		position: relative;
		top: -75px;
		height: 70px;
		font-family: DINNextMedium;
		color: $color-black;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 14px;
	}

	&[disabled] {
		visibility: hidden;
		svg {
			fill: $color-gray-5c;
		}
		& > div {
			color: $color-cool-gray-1c;
		}
	}
}