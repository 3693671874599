@import "../../colors.scss";

.bubbleButton {
	margin: 0;
	padding: 0;
	border: none;
	position: relative;
	background: inherit;
	font-family: DINNextMedium;
	svg {
		fill: $color-7472-c;
	}
	&:hover {
		svg {
			fill: $color-571-c;
		}
	}
	& > div {
		position: absolute;
		width: 100%;
		height: 70px;
		display: flex;
		justify-content: center;
		align-items: center;
		top: 0;
		color: $color-black;
		font-size: 14px;
	}
	&[disabled] {
		svg {
			fill: $color-gray-5c;
		}
		& > div {
			color: $color-cool-gray-1c;
		}
	}
}