.language-selector {
  background: #fff;
  width: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  top: 0;
  border-bottom-right-radius: 1rem;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: column;
  padding: 0.3rem 0;
  div {
    margin: 0.2rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    div {
      padding-top: 0.2rem;
      white-space: nowrap;
      font-size: 0.7rem;
    }
  }
}