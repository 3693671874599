@import "./colors.scss";

.App {
  text-align: center;
  background: $color-white;
  min-height: 100%;
  color: $color-black;
  border: 1px solid $color-black;
  border-top: none;
  border-bottom: none;
  margin: 0;
}

.app-layout {
  max-width: 768px;
  margin: 0 auto;
}

main {
	padding: 1rem;
	padding-bottom: 140px;
	overflow-y: scroll;
}