@import "../../colors.scss";

.button {
	font-family: DINNextMedium;
	line-height: 1;
	font-size: 1.2rem;
	text-transform: uppercase;

	background: $color-7472-c;
	color: $color-black;
	
	display: block;
	width: 100%;

	margin: 0.5rem 0;
	padding: 1rem;

	border: none;

	&:hover {
		background: $color-571-c;
	}
}