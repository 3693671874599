@import "../colors.scss";

.adminPage {
	text-align: left;
	.noBreak {
		white-space: nowrap;
	}
	.form-button {
		border: 1px solid $color-7472-c;
		padding: 1rem;
		margin-right: 1rem;
		background: $color-571-c;
		color: $color-black;
		font-family: DINNextMedium;
		&:nth-last-child(1) {
			margin-right: 0;
		}
		&[disabled] {
			color: $color-cool-gray-1c;
			background: $color-gray-5c;
		}
	}
	.sub-questions-section {
		margin-top: 1rem;
	}
	.form-button-container {
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}
	input {
		padding: 0.5rem;
		margin-right: 1rem;
		&[type=text],
		&[type=password] {
			width: 100%;
			margin-right: 0;
			margin-bottom: 1rem;
			border: 1px solid $color-7472-c;
		}
	}
	.iconButton {
		border: none;
		padding: 0;
		margin: 0;
		background: $color-white;
	}
	table {
		margin-bottom: 1rem;
		border-collapse: collapse;
		width: 100%;
		td,
		th {
			padding: 0.5rem;
		}
		td {
			&:nth-child(1) {
				width: 100%;
			}
			border: solid 1px $color-7472-c;
		}
		th {
			background: $color-black;
			color: $color-white;
			border: 1px solid $color-black;
			font-family: DINNextMedium;
		}
	}
	.add-icon-container {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.add-start-question {
		margin: 2rem 0;
	}

	.capitalized {
		text-transform: capitalize;
	}

	.editSection {
		margin-top: 1rem;
		padding-bottom: 1.5rem;
	}

	.editItem {
		margin-bottom: 1rem;
		strong {
			margin-right: 1rem;
		}
	}
	.subQuestions {
		padding-top: 0.5rem;
		font-family: 0.8rem;
	}
}

.start-questions {
	text-align: left;
	padding: 1rem 2rem;
	border-radius: 5px;
	background: $color-571-c;
}

.errorMessage {
	margin-top: 2rem;
}

.spaceAbove {
	margin-top: 2rem;
}

.text-center {
	text-align: center;
}

.questionNumber {
	font-size: 0.8rem;
	margin-bottom: 2rem;
}

.navigationButtonsContainer {
	display: flex;
	justify-content: space-between;
	margin-top: 1rem;
}

.feedbackView {
	.rating {
		div {
			display: inline-block;
			svg {
				margin: 0 0.5rem;
				cursor: pointer;
			}
		}
	}
}

.startPageView {
	.sponsorMessage {
		display: flex;
		justify-content: center;
		width: 100%;
		div {
			padding-top: 2rem;
			font-size: 0.8rem;
			max-width: 768px;
		}
	}
}

.info-before-view {
	text-align: left;
}
