@import "../../colors.scss";

.admin-menu {
  background: $color-571-c;
  border-radius: 10px;
  ul {
    li {
      display: inline-block;
      cursor: pointer;
      font-weight: bold;
      transition: background-color 150ms;
      &:hover {
        background: $color-black;
        transition: background-color 150ms;
        border-radius: 5px;
        color: $color-white;
        a {
          color: $color-white;
        }
      }
      a {
        display: inline-block;
        padding: 0.5rem 1rem;
        text-decoration: none;
        color: $color-black;
      }
      &.activeItem {
        background: $color-7472-c;
        border-radius: 5px;
        &:hover {
          background: $color-black;
        }
      }
    }
  }
}

.admin-page-container {
  padding-top: 2rem;
}