@import "../../colors.scss";

.footerMenu {
	position: fixed;
	bottom: 0;
	// left: 0;
	padding: 1rem 1rem;
	background: $color-cool-gray-1c;
	color: $color-black;
	width: 100%;
	border-top: $color-gray-5c 2px solid;
	max-width: 766px;
	margin: 0 auto;
	display: flex;
	margin-left: -1rem;
	justify-content: space-between;
}