@import "./colors.scss";

@font-face {
    font-family: DINNext;
    src: url("/fonts/DINNextLTPro-Regular.otf") format("opentype");
}

@font-face {
    font-family: DINNext;
    font-weight: bold;
    src: url("/fonts/DINNextLTPro-Bold.otf") format("opentype");
}

@font-face {
    font-family: DINNextBold;
    src: url("/fonts/DINNextLTPro-Bold.otf") format("opentype");
}

@font-face {
    font-family: DINNextLight;
    src: url("/fonts/DINNextLTPro-Light.otf") format("opentype");
}

@font-face {
    font-family: DINNextMedium;
    src: url("/fonts/DINNextLTPro-Medium.otf") format("opentype");
}

html,
body {
    height: 100%;
    margin: 0;
    padding: 0;
}

html {
    box-sizing: border-box;
    font-size: 10px;
}

@media only screen and (min-width: 600px)  {
    html {
        font-size: 14px;
    }
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    font-family: DINNextLight;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	letter-spacing: 0.03rem;
	background: $color-gray-5c;
}

#root {
	min-height: 100%;
	height: 100%;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 1rem 0;
    padding: 0;
}

/* Huvudrubrik */
h1 {
    font-family: DINNextBold;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
}

/* mellanrubrik */
h2 {
    font-family: DINNextMedium;
    text-transform: capitalize;
    letter-spacing: 0.05rem;
}

/* ingresser */
p {
    font-family: DINNext;
}

button {
	cursor: pointer;
}