@import "../../colors.scss";

.loginForm {
    .content {
		width: 80%;
		margin-left: 10%;
		margin-bottom: 2rem;
        label {
			margin-top: 2rem;
			margin-bottom: 0.5rem;
			display: block;
			width: 100%;
			font-weight: bold;
			text-align: left;
        }

        input {
			border: 1px solid $color-7472-c;
			padding: 1rem;
            width: 100%;
        }
    }
}