@import "../../colors.scss";

.questionCard {
	font-family: DINNextMedium;
	font-size: 1.6rem;
	padding: 2rem 2rem;
	text-align: left;
	.subQuestions {
		font-size: 1.2rem;
		margin-top: 1rem;
		padding: 1rem 1rem;
		border-radius: 5px;
		background: $color-571-c;
		
		div {
			margin: 1.5rem 0;
		}
	}
}